// 设备消息
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 分页查询-设备日志列表
export const queryDeviceLogPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/log/page',
    params,
  })
}

// 日志类型
export const queryDeviceLogType = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/log/type/query',
    params,
  })
}

// 日志-消息来源
export const queryDeviceLogTransTreeList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/log/trans/treeList/query',
    params,
  })
}

// 设备日志历史导出
export const exportDeviceLog = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/log/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 日志-日志溯源
export const queryDeviceSourceLog = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/log/listDeviceLogById',
    params,
  })
}

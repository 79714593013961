<template>
  <div class="member-page">
    <div class="ws_table">
      <el-table class="table ws_common_table" :data="tableData" v-loading="tableLoading">
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          v-bind="{
            ...item
          }"
          show-overflow-tooltip
        />
        <el-table-column label="操作" width="200" #default="scope">
          <OperationButtonList
            :buttonList="buttonList"
            :scope="scope.row"
          ></OperationButtonList>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        class="ws_mt50"
        v-model:current-page="pageParams.curPage"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="PAGE_SIZES"
        layout="->, total, sizes, prev, pager, next, jumper "
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <ProductForm />
</template>
<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue';
import OperationButtonList from '@/components/operation-button-list';
import { useRouter } from 'vue-router';
import { queryMemberPage } from '@/api';
import { CODE_ENUM, PAGE_SIZES } from '@/common';
import moment from 'moment';

const router = useRouter();

const toDetail = ({ userId, mobile }) => {
  router.push({
    name: 'member-detail',
    query: {
      userId,
      mobile
    }
  });
};

const buttonList = [
  {
    text: '详情',
    onClick: toDetail
  }
];

const tableData = ref([{}]);

const pageParams = ref({
  curPage: 1,
  pageSize: 10
});
const total = ref(0);

const handleSizeChange = () => {
  pageParams.value.curPage = 1;
  fetchList();
};
const handleCurrentChange = () => {
  fetchList();
};

const col = [
  {
    prop: 'mobile',
    label: '会员账号'
  },
  {
    prop: 'deviceCount',
    label: '设备数量'
  },
  {
    prop: 'createTime',
    label: '注册时间',
    formatter: ({ createTime }) => {
      return moment(createTime).format('YYYY-MM-DD HH:mm:ss');
    }
  }
];

const tableLoading = ref(false);
const fetchList = async () => {
  tableLoading.value = true;
  const res = await queryMemberPage({
    ...pageParams.value
  }).finally(() => {
    tableLoading.value = false;
  });
  if (res?.code === CODE_ENUM.SUCCESS) {
    tableData.value = res?.data?.records || [];
    total.value = res?.data?.total || 0;
  }
};

fetchList();
</script>
<style lang="scss" scoped src="./index.scss"></style>

import { get, resolveGet } from '@/request'
import { SUFFIX } from '@/common'

// 逆地理编码
export const queryLocationRgeo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/third/rgeo/query',
    params,
    context: true,
  })
}

<template>
  <div class="device-page">
    <AdvancedQueryLayout
      class="advance-container"
      ref="advancedRef"
      :model="formInline"
      @fetch-list="fetchList"
      :border="true"
    >
      <el-form-item label="订单编号" prop="tradeOrderNo">
        <el-input
          v-model="formInline.tradeOrderNo"
          clearable
          maxlength="50"
          class="ws_select"
        />
      </el-form-item>
      <el-form-item label="设备IMEI" prop="deviceNo">
        <el-input
          v-model="formInline.deviceNo"
          clearable
          maxlength="50"
          class="ws_select"
        />
      </el-form-item>
      <el-form-item label="套餐" prop="packageId">
        <el-select v-model="formInline.packageId" class="ws_select" clearable>
          <el-option
            v-for="item in packageList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="会员账号" prop="username">
        <el-input
          v-model="formInline.username"
          clearable
          maxlength="50"
          class="ws_select"
        />
      </el-form-item>
      <el-form-item label="订单状态" prop="tradeStateCode">
        <el-select
          v-model="formInline.tradeStateCode"
          class="ws_select"
          clearable
        >
          <el-option
            v-for="item in statusOps"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时间" prop="time" class="time">
        <el-date-picker
          v-model="formInline.time"
          unlink-panels
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :editable="false"
        />
      </el-form-item>
    </AdvancedQueryLayout>

    <div class="ws_table">
      <el-table
        class="table ws_common_table"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          v-bind="{
            ...item,
          }"
          show-overflow-tooltip
        />
        <el-table-column label="订单状态" width="160" #default="scope">
          <el-tag :type="statusTagMap[scope.row.orderStateCode]">{{
            scope.row.orderStateValue
          }}</el-tag>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        class="ws_mt50"
        v-model:current-page="pageParams.curPage"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="PAGE_SIZES"
        layout="->, total, sizes, prev, pager, next, jumper "
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import AdvancedQueryLayout from '@/components/advanced-query-layout'
import moment from 'moment'
import { CODE_ENUM, ORDER_STATUS_CODE, PAGE_SIZES } from '@/common'
import { formatFormTime, transformNumber } from '@/utils'
import { queryTradeorderPage, queryPrivatePackageList } from '@/api'

// const statusTextMap = {
//   [ORDER_STATUS_CODE.ORDER_UNPAY]: '未f付款',
//   [ORDER_STATUS_CODE.ORDER_PAY_FINISH]: '已f付款',
//   [ORDER_STATUS_CODE.ORDER_CANCLE]: '已取消'
// };

const statusTagMap = {
  [ORDER_STATUS_CODE.ORDER_UNPAY]: 'warning',
  [ORDER_STATUS_CODE.ORDER_PAY_FINISH]: 'success',
  [ORDER_STATUS_CODE.ORDER_CANCLE]: 'info',
}

const statusOps = [
  {
    label: '未付款',
    value: ORDER_STATUS_CODE.ORDER_UNPAY,
  },
  {
    label: '已付款',
    value: ORDER_STATUS_CODE.ORDER_PAY_FINISH,
  },
  {
    label: '已取消',
    value: ORDER_STATUS_CODE.ORDER_CANCLE,
  },
]

const advancedRef = ref()

const tableData = ref([])

const formInline = ref({
  productKey: '',
  time: [],
})

const pageParams = ref({
  curPage: 1,
  pageSize: 10,
})
const total = ref(0)

const handleSizeChange = () => {
  pageParams.value.curPage = 1
  fetchList()
}
const handleCurrentChange = () => {
  fetchList()
}

const col = [
  {
    prop: 'tradeOrderNo',
    label: '订单编号',
  },
  {
    prop: 'deviceNo',
    label: '设备IMEI',
  },
  {
    prop: 'productName',
    label: '产品名称',
  },
  {
    prop: 'packageName',
    label: '套餐名称',
    formatter: ({ packageName, chargModeValue }) => {
      return packageName + chargModeValue
    },
  },
  {
    prop: 'chargModePeriod',
    label: '生效周期',
  },
  {
    prop: 'tradingAmount',
    label: '订单金额（元）',
    width: 170,
    className: 'align-right',
    formatter: ({ tradingAmount }) => {
      return transformNumber(tradingAmount)
    },
  },
  {
    prop: 'username',
    label: '会员账号',
  },
  {
    prop: 'tradeOrderTime',
    label: '创建时间',
    formatter: ({ tradeOrderTime }) => {
      return moment(tradeOrderTime).format('YYYY-MM-DD HH:mm:ss')
    },
  },
]

const getMoTime = (keepAliveData) => {
  return {
    ...formatFormTime(keepAliveData.time, ['startTime', 'endTime']),
  }
}

const tableLoading = ref(false)
const fetchList = async (queryData) => {
  const keepAliveData = advancedRef.value.getKeepAliveData()
  const { data, reset } = queryData || {}

  if (typeof reset === 'boolean') {
    pageParams.value.curPage = 1
  }
  tableLoading.value = true
  const res = await queryTradeorderPage({
    ...pageParams.value,
    ...data,
    ...keepAliveData,
    ...getMoTime(keepAliveData),
  }).finally(() => {
    tableLoading.value = false
  })
  if (res?.code === CODE_ENUM.SUCCESS) {
    tableData.value = res?.data?.records || []
    total.value = res?.data?.total || 0
  }
}

onMounted(() => {
  fetchList()
})

const packageList = ref([])
const querypackageTreeList = async () => {
  const res = await queryPrivatePackageList()
  if (res?.code === CODE_ENUM.SUCCESS) {
    packageList.value = res.data
  }
}
querypackageTreeList()
</script>
<style lang="scss" scoped src="./index.scss"></style>

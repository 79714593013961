import { ref } from 'vue'
import { defineStore } from 'pinia'
import { queryProductType, queryProductGatewayList } from '@/api'
import { CODE_ENUM } from '@/common'

export const useProductStore = defineStore('product', () => {
  // 网关产品
  const productGetewayType: any = ref([])
  const queryGetewayType = async () => {
    const res: any = await queryProductGatewayList()
    if (res?.code === CODE_ENUM.SUCCESS) {
      productGetewayType.value = (res.data || []).map(
        ({ productKey, productName, ...p }: any) => {
          return {
            code: productKey,
            name: productName,
            ...p,
          }
        }
      )
    }
  }

  // 非网关产品类型
  const productType: any = ref([])
  const queryType = async () => {
    const res: any = await queryProductType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      productType.value = res.data || []
    }
  }

  return { productType, queryType, queryGetewayType, productGetewayType }
})

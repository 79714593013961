import { ref } from 'vue'
import { defineStore } from 'pinia'
import { queryDeviceLogTransTreeList, queryDeviceLogType } from '@/api'
import { CODE_ENUM } from '@/common'

export const useMsgStore = defineStore('msg', () => {
  const msgType: any = ref([])
  const queryType = async () => {
    const res: any = await queryDeviceLogTransTreeList()
    if (res?.code === CODE_ENUM.SUCCESS) {
      msgType.value = res.data || []
    }
  }

  const msgLogType: any = ref([])
  const queryLogType = async () => {
    const res: any = await queryDeviceLogType()
    if (res?.code === CODE_ENUM.SUCCESS) {
      msgLogType.value = res.data || []
    }
  }

  return { msgType, queryType, msgLogType, queryLogType }
})

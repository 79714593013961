<template>
  <div class="member-detail-page">
    <div class="member-info">
      <div class="box">会员</div>
      <p>会员账号 ：{{ mobile }}</p>
    </div>
    <div class="ws_table">
      <p class="total ws_mb30">已绑定设备：{{ total }} 个</p>
      <el-table class="table" :data="tableData" v-loading="tableLoading">
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          v-bind="{
            ...item,
          }"
          show-overflow-tooltip
        />
        <el-table-column label="套餐到期时间" width="190" #default="scope">
          <span :class="{ 'red-text': isEndTime(scope.row.packageEndTime) }">{{
            getTime(scope.row.packageEndTime)
          }}</span>
        </el-table-column>
        <el-table-column label="设备激活状态" width="160" #default="scope">
          <el-tag :type="statusTagMap[scope.row.activationStatusCode]">{{
            statusTextMap[scope.row.activationStatusCode]
          }}</el-tag>
        </el-table-column>
        <template #empty>
          <el-empty description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        class="ws_mt50"
        v-model:current-page="pageParams.curPage"
        v-model:page-size="pageParams.pageSize"
        :page-sizes="PAGE_SIZES"
        layout="->, total, sizes, prev, pager, next, jumper "
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>

  <ProductForm />
</template>
<script setup>
import { onMounted, ref, watch, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import { queryMemberDetail } from '@/api'
import { CODE_ENUM, PAGE_SIZES, DEVICE_STATUS_CODE } from '@/common'
import moment from 'moment'

const statusTextMap = {
  [DEVICE_STATUS_CODE.NO_ACTIVATE]: '待激活',
  [DEVICE_STATUS_CODE.ACTIVATED]: '已激活',
}

const statusTagMap = {
  [DEVICE_STATUS_CODE.NO_ACTIVATE]: 'info',
  [DEVICE_STATUS_CODE.ACTIVATED]: 'primary',
}

const $route = useRoute()

const params = $route.query

const userId = params.userId
const mobile = params.mobile

const tableData = ref([])

const pageParams = ref({
  curPage: 1,
  pageSize: 10,
})
const total = ref(0)

const handleSizeChange = () => {
  pageParams.value.curPage = 1
  fetchList()
}
const handleCurrentChange = () => {
  fetchList()
}

const col = [
  {
    prop: 'deviceNo',
    label: '设备IMEI',
  },
  {
    prop: 'productName',
    label: '产品名称',
  },
  {
    prop: 'productModel',
    label: '型号',
  },

  {
    prop: 'packageName',
    label: '套餐',
  },
  // {
  //   prop: 'packageEndTime',
  //   label: '套餐到期时间',
  //   formatter: ({ packageEndTime }) => {
  //     return '<span style="color:red">aaa</span>'
  //     // return packageEndTime
  //     //   ? moment(packageEndTime).format('YYYY-MM-DD HH:mm:ss')
  //     //   : '-';
  //   }
  // }
]

const getTime = (packageEndTime) => {
  return packageEndTime
    ? moment(packageEndTime).format('YYYY-MM-DD HH:mm:ss')
    : '-'
}

const isEndTime = (packageEndTime) => {
  const nowTime = moment().valueOf()
  return nowTime > packageEndTime
}

const tableLoading = ref(false)
const fetchList = async () => {
  tableLoading.value = true
  const res = await queryMemberDetail({
    ...pageParams.value,
    userId,
  }).finally(() => {
    tableLoading.value = false
  })
  if (res?.code === CODE_ENUM.SUCCESS) {
    tableData.value = res?.data?.records || []
    total.value = res?.data?.total || 0
  }
}

fetchList()
</script>
<style lang="scss" scoped src="./index.scss"></style>

// 营销管理
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 用户分页列表
export const queryPackagePage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/package/page/query',
    params,
  })
}

//撤销-发布套餐
export const packageInfoPublish = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/package/info/publish',
    params,
  })
}

//添加套餐信息
export const packageInfoAdd = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/package/info/add',
    params,
  })
}

//更新套餐信息
export const packageInfoUpdate = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/package/info/update',
    params,
  })
}

//添加套餐信息
export const getPackageInfo = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/package/info/get',
    params,
  })
}

//删除套餐
export const packageDelete = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/package/info/delete',
    params,
  })
}

//查询个人套餐列表
export const queryPrivatePackageList = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/package/private/list/query',
    params,
  })
}

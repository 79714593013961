import { ref } from 'vue'
import { defineStore } from 'pinia'
import { queryDeviceThingUnitList } from '@/api'
import { CODE_ENUM } from '@/common'

export const useModelStore = defineStore('model', () => {
  const unitList: any = ref([])

  const queryUnitList = async () => {
    const res: any = await queryDeviceThingUnitList()
    if (res?.code === CODE_ENUM.SUCCESS) {
      unitList.value = res.data
    }
  }

  const getUnitName = (value: any) => {
    if (value) {
      return unitList.value.filter((item: any) => {
        return item.code === value
      })?.[0]?.name
    }
  }

  return { unitList, queryUnitList, getUnitName }
})

import { resolvePost } from '@/request'
import { SUFFIX } from '@/common'

// 登陆
export const oauthToken = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    context: true,
  })
}
// 退出登陆
export const oauthRemoveToken = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/oauth/remove/token',
    params,
    userConfig: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  })
}

// 修改密码
export const passwordUpdate = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/password/update',
    params,
    context: true,
  })
}

// 发送验证码
export const sendVerificationcode = (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/verificationcode/username/mobile/send',
    params,
    userConfig: {
      headers: {
        emptyAuthorization: true,
      },
    },
  })
}

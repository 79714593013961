// 系统管理
import { get, post, resolveGet, resolvePost } from '@/request'

import { SUFFIX } from '@/common'

// 根据网关SN获取子设备列表
export const queryDeviceThingSubDeviceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/subDeviceList/queryBySN',
    params,
  })
}

// 根据网关ID获取子设备列表
export const queryDeviceThingSubDeviceListById = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/subDeviceList/queryById',
    params,
  })
}

// 根据设备Id获取物模型属性元数据
export const queryDeviceThingMetadataList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/metadataList/query',
    params,
  })
}

// 获取物模型列表
export const queryDeviceThingPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/page/query',
    params,
  })
}

// 获取物模型列表
export const queryDeviceThingInfo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/info/get',
    params,
  })
}

// 新增物模型
export const deviceThingInfoAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/info/add',
    params,
  })
}

// 新增物模型
export const deviceThingInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/info/update',
    params,
  })
}

// 删除物模型
export const deviceThingInfoDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/info/delete',
    params,
  })
}

// 获取物模型单位数据
export const queryDeviceThingUnitList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/dataUnit/list',
    params,
  })
}

//获取产品模板分页
export const queryDeviceThingTemplatePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/base/page/query',
    params,
  })
}

//添加产品模板
export const deviceThingTemplateAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/base/info/add',
    params,
  })
}

//修改产品模板
export const deviceThingTemplateUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/base/info/update',
    params,
  })
}

//删除产品模板
export const deviceThingTemplateDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/deviceThing/base/info/delete',
    params,
  })
}

// 获取网关及子设备最新设备属性
export const queryDevicePhysicsLastOnceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/physicsLastOnce/gatewayDeviceId/list',
    params,
  })
}
